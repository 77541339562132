.highlight {
    background-color: #fffbe6;
}

.actionWrap {
    display: flex;
    padding-bottom: 16px;
    align-items: center;
}

.praise {
    min-width: 50px;
}

.switchWrap {
    display: flex;
    align-items: center;
    margin: 0 16px;
}

.commentContent {
    //border: 3px solid;
}

