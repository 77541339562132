.layout {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
}

.content {
    //flex: 1;
    display: flex;
}

.contentLayout {
    background-color: #fff;
    display: flex;
}

.innerContent {
    display: flex;
    flex-direction: column;
}

