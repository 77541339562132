.container {
    padding: 12px 0;
}

.selLine {
    display: flex;
}

.selectedBtn {
    height: auto;
    padding-top: 0;
    padding-bottom: 0;
}

.selectedArea {
    //padding-top: 24px;
    padding: 0 24px 24px;
    //margin: 12px 0;
    //border-radius: 10px;
    //background-color: #fafafa;
}

.selectedItems {
    padding-top: 24px;
}

.hidden {
    display: none;
}

