@import '~antd/es/style/themes/default';

.item {
    padding: 16px;
    border-radius: 8px;

    &:hover,
    &:focus {
        background-color: @background-color-light;
    }
}

.titleWrap {
    display: flex;
    justify-content: space-between;
}

