@import '~antd/es/style/themes/default';

.container {
    display: flex;
    padding: 8px 0;

    &:hover {
        background-color: @menu-item-active-bg;
    }
}

.title {
    //font-weight: bold;
    flex: auto;
}

.actions {
    display: flex;
    width: 200px;
}

.action {
    margin: 0 8px;
    display: flex;
    align-items: center;
    flex: 1;
}

.actionTitle {
    margin-right: 8px;
}

