.grid {
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.icon {
    font-size: 24px;
}

.title {
    margin-top: 10px;
}

