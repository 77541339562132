@highlight-color: #576b95;

.typo {
    position: relative;

    & * {
        max-width: 100% !important;
        word-wrap: break-word !important;
    }

    ul,
    ol,
    li {
        margin: 0;
        padding: 0;
    }

    /* HTML5 媒体文件跟 img 保持一致 */
    audio,
    canvas,
    video {
        display: inline-block;
        max-width: 100%;
        height: auto;

        // 因为视频需要在带水印的页面播放，所以需要将其设为顶层
        position: relative;
        z-index: 3000;
    }

    /* 去掉各Table cell 的边距并让其边重合 */
    table {
        border-collapse: collapse;
        border-spacing: 0;
    }

    /* 去除默认边框 */
    fieldset,
    img {
        border: 0;
    }

    /* 块/段落引用 */
    blockquote {
        position: relative;
        color: #999;
        font-weight: 400;
        border-left: 1px solid @highlight-color;
        padding-left: 1em;
        margin: 1em 3em 1em 2em;
    }

    @media only screen and (max-width: 640px) {
        blockquote {
            margin: 1em 0;
        }
    }

    /* Firefox 以外，元素没有下划线，需添加 */
    acronym,
    abbr {
        border-bottom: 1px dotted;
        font-variant: normal;
        text-decoration: none;
    }

    /* 添加鼠标问号，进一步确保应用的语义是正确的（要知道，交互他们也有洁癖，如果你不去掉，那得多花点口舌） */
    abbr {
        cursor: help;
    }

    /* 一致的 del 样式 */
    del {
        text-decoration: line-through;
    }

    address,
    caption,
    cite,
    code,
    dfn,
    //em,
    th,
    var {
        font-style: normal;
        font-weight: 400;
    }

    /* 去掉列表前的标识, li 会继承，大部分网站通常用列表来很多内容，所以应该当去 */
    ul,
    ol {
        list-style: none;
    }

    /* 对齐是排版最重要的因素, 别让什么都居中 */
    caption,
    th {
        text-align: left;
    }

    /* 统一上标和下标 */
    sub,
    sup {
        font-size: 75%;
        line-height: 0;
        position: relative;
    }

    sup {
        top: -0.5em;
    }

    sub {
        bottom: -0.25em;
    }

    :root sub,
    :root sup {
        vertical-align: baseline; /* for ie9 and other modern browsers */
    }

    /* 让链接在 hover 状态下显示下划线 */
    a {
        color: @highlight-color;
        border-bottom: 1px solid @highlight-color;
    }

    a:hover {
        //text-decoration: underline;
        border-bottom-color: #555;
        color: #555;
        text-decoration: none;
    }

    /* 默认不显示下划线，保持页面简洁 */
    ins {
        text-decoration: none;
    }

    /* 专名号：虽然 u 已经重回 html5 Draft，但在所有浏览器中都是可以使用的，
         * 要做到更好，向后兼容的话，添加 class="typo-u" 来显示专名号
         * 关于 <u> 标签：http://www.whatwg.org/specs/web-apps/current-work/multipage/text-level-semantics.html#the-u-element
         * 被放弃的是 4，之前一直搞错 http://www.w3.org/TR/html401/appendix/changes.html#idx-deprecated
         * 一篇关于 <u> 标签的很好文章：http://html5doctor.com/u-element/
         */
    u {
        text-decoration: underline;
    }

    /* 标记，类似于手写的荧光笔的作用 */
    mark {
        background: #fffdd1;
        border-bottom: 1px solid #ffedce;
        padding: 2px;
        margin: 0 5px;
    }

    /* 代码片断 */
    pre,
    code,
    pre tt {
        font-family: Courier, 'Courier New', monospace;
    }

    pre {
        background: #f8f8f8;
        border: 1px solid #ddd;
        padding: 1em 1.5em;
        display: block;
        -webkit-overflow-scrolling: touch;
    }

    /* 一致化 horizontal rule */
    hr {
        border: none;
        border-bottom: 1px solid #cfcfcf;
        margin-bottom: 0.8em;
        height: 10px;
    }

    /* 底部印刷体、版本等标记 */
    small,
    figcaption {
        font-size: 0.9em;
        color: #888;
    }

    strong,
    b {
        font-weight: 900;
    }

    /* 可拖动文件添加拖动手势 */
    [draggable] {
        cursor: move;
    }

    .clearfix::before,
    .clearfix::after {
        content: '';
        display: table;
    }

    .clearfix::after {
        clear: both;
    }

    .clearfix {
        zoom: 1;
    }

    /* 保证块/段落之间的空白隔行 */
    p,
    pre,
    ul,
    ol,
    dl,
    form,
    hr,
    table,
    blockquote {
        margin-bottom: 1.2em;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: 100;
        //color: #000;
        line-height: 1.35;
        margin-top: 1.2em;
        margin-bottom: 0.6em;
    }

    h1 {
        font-size: 1.8em;
    }

    h2 {
        font-size: 1.6em;
    }

    h3 {
        font-size: 1.4em;
    }

    h4,
    h5,
    h6 {
        font-size: 1.2em;
    }

    /* 在文章中，应该还原 ul 和 ol 的样式 */
    ul {
        margin-left: 1.3em;
        list-style: disc;
    }

    ol {
        list-style: decimal;
        margin-left: 1.9em;
    }

    li ul,
    li ol {
        margin-bottom: 0.8em;
        margin-left: 2em;
    }

    li ul {
        list-style: circle;
    }

    /* 同 ul/ol，在文章中应用 table 基本格式 */
    table th,
    table td,
    table caption {
        border: 1px solid #ddd;
        padding: 0.5em 1em;
        color: #666;
    }

    table th {
        background: #fbfbfb;
    }

    table thead th th {
        background: #f1f1f1;
    }

    table caption {
        border-bottom: none;
    }

    em {
        font-style: italic;
    }

    //noinspection Stylelint
    legend,
    caption {
        //color: #000;
        font-weight: inherit;
    }

    /* Responsive images */
    img {
        max-width: 100%;
        height: auto;
    }
}

