.container {
    display: flex;
}

.header {
    border: 1px solid;
}

.preview {
    flex: 1;
    width: 50%;
}

.history {
    flex: 1;
    width: 50%;
}

