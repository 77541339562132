.groupList {
    list-style-type: none;

    li {
        margin-bottom: 8px;
    }
}

.groupName {
    font-weight: bold;
    font-size: 16px;
}

