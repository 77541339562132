@import '~antd/es/style/themes/default';

.container {
    display: flex;
    flex: 1;
    overflow: hidden;
}

.sidePanel {
    width: 300px;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    border-right: 1px solid #f0f0f0;
    position: relative;
}

.contentPanel {
    display: flex;
    flex: auto;
    overflow: auto;
    flex-direction: column;
}

.enableScroll {
    overflow-y: auto;
}

.panelTitleBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    min-height: 49px;
    border-bottom: 1px solid #f0f0f0;
}

.panelTitle {
    font-size: 18px;
    font-weight: bold;
    display: flex;
    align-items: center;
}

.panelTitleIcon {
    color: @primary-color;
    margin-right: 6px;
}

.subTitle {
    margin-left: 16px;
    color: @text-color-secondary;
    font-size: @page-header-heading-sub-title;
    line-height: @line-height-base;
}

.panelContent {
    overflow-y: auto;
    min-height: 500px;
}

