@import '~antd/es/style/themes/default';

@app-list-item-hover-bg: @primary-1;
@app-list-item-active-bg: @primary-1;
@app-list-item-title-highlight-color: @primary-color;

.item {
    padding-left: 16px;
    padding-right: 16px;
    cursor: pointer;
    display: flex;
    flex-direction: column;

    &:hover {
        background-color: @background-color-light;
    }
}

.itemSelected {
    background-color: @app-list-item-active-bg;

    :global {
        .ant-list-item-meta-title {
            color: @app-list-item-title-highlight-color;
        }
    }
}

.content {
    flex: auto;
}

