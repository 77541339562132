.wrap {
    background-color: #fff;
}

.modal {
    width: 100%;
    max-width: 100%;
    top: 0;
    bottom: 0;
    margin: 0;
    padding-bottom: 0;

    :global {
        .ant-modal-content {
            box-shadow: none;
        }

        .ant-modal-footer {
            background-color: #fff;
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            margin: 0 auto;
            padding: 24px;
        }
    }
}

.container {
    display: flex;
    margin: 24px auto 0;
    padding-bottom: 100px;
}

