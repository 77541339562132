.articleTitle {
    padding-bottom: 1em;
    margin-bottom: 1em;
    border-bottom: 3px double #eee;
}

.title {
    font-size: 24px;
    font-weight: 400;
}

.subTitle,
.description {
    font-family: Palatino, Optima, Georgia, serif;
    color: #999 !important;
    font-size: 16px !important;
    font-weight: 100;
    line-height: 1.35;
}

