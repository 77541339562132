.container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.tabs {
    flex-shrink: 0;
    padding-top: 24px;
}

.tabsTitle {
    font-size: 16px;
    font-weight: bold;
    margin: 0 48px;
}

.content {
    flex: auto;
    overflow-y: auto;
    padding: 0 48px;
}

