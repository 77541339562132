@import '~antd/es/style/themes/default';

.title {
    flex: 1;
    background-color: #fafafa;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 24px;
    border-top: 1px solid #f0f0f0;
    color: #8c8c8c;
}

.container {
    width: 160px;
    height: 220px;
    margin: 12px;
    border-radius: 10px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    overflow: hidden;

    &:hover {
        box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.2);

        .title {
            color: #434343;
        }
    }
}

.bigContainer {
    width: 200px;
    height: 260px;
}

.btnPin {
    position: absolute;
    right: 0;
    top: 0;
}

.imageWrap {
    height: 140px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 2;
}

