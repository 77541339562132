.container {
    display: flex;
    //margin: 0 8px 24px;
}

.title {
    flex-shrink: 0;
}

.content {
    flex: auto;
    margin-left: 16px;
}

