//@app-layout-bg-color: red;
@app-layout-bg-color: #f6f9fa;
@app-layout-content-bg-color: #fff;

.container {
    background-color: @app-layout-bg-color;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
}

.header {
    background-color: @app-layout-bg-color;
}

.layout {
    background-color: @app-layout-bg-color;
}

.sidebar {
    background-color: @app-layout-bg-color;
    overflow-y: auto;
    padding-top: 24px;
    margin-bottom: 64px;

    :global {
        .ant-menu {
            background-color: @app-layout-bg-color;
            border-right: none;
        }

        .ant-layout-sider-trigger {
            background-color: @app-layout-bg-color;
        }
    }
}

.contentContainer {
    background-color: @app-layout-content-bg-color;
    border-top-left-radius: 20px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    box-shadow: -1px -1px 30px rgba(0, 0, 0, 0.1);
    z-index: 99;
}

.noSiderContentContainer {
    margin: 0 50px;
    border-top-right-radius: 20px;
}

.content {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex: auto;
}

