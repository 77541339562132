.fixed {
    position: fixed;
    width: 100%;
    height: 100%;
}

.container {
    //height: 100vh;
    background: #f0f2f5 url('../../asset/login-bg.svg') no-repeat top;
    background-size: contain;
}

.bottomBg {
    background-size: contain;
    background-image: url('../../asset/bottom.png');
    background-position: bottom;
    background-repeat: repeat-x;
    overflow-x: hidden;
    overflow-y: auto;
}

.logo {
    text-align: center;
    height: initial;
    line-height: inherit;
    padding: 150px 0 0;

    img {
        margin: 0 auto;
        width: 64px;
        height: 64px;
        display: block;
    }

    h1 {
        margin: 20px 0 0;
        text-shadow: 0 1px 1px #c5c5c5;
    }
}

.content {
    max-width: 300px;
    margin: 50px auto;
}

