@import '~antd/es/style/themes/default';

.container {
    display: flex;
}

.masterContainer {
    flex: auto;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.slaveContainer {
    display: flex;
    flex-direction: column;
    position: relative;
    border-left: 1px solid #f0f0f0;
}

.slaveContent {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.slaveHide {
    display: none;
}

.close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: @zindex-popup-close;
    padding: 0;
    color: @modal-close-color;
    font-weight: 700;
    line-height: 1;
    text-decoration: none;
    background: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
    transition: color 0.3s;

    &:focus,
    &:hover {
        color: @icon-color-hover;
        text-decoration: none;
    }
}

.closeX {
    display: block;
    width: 32px;
    height: 32px;
    font-size: @font-size-lg;
    font-style: normal;
    line-height: 32px;
    text-align: center;
    text-transform: none;
    text-rendering: auto;
}

