@import '~antd/es/style/themes/default';

.roleItem {
    padding-left: 16px;
    padding-right: 16px;
    cursor: pointer;
    display: flex;

    &:hover {
        background-color: @menu-item-active-bg;
    }
}

.roleItemSelected {
    background-color: @menu-item-active-bg;

    :global {
        .ant-list-item-meta-title {
            color: @menu-highlight-color;
        }
    }
}

.roleContent {
    flex: auto;
}

.checkArea {
    margin-right: 8px;
    margin-top: 12px;
}

