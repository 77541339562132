//noinspection ALL
:global(.screen-mobile) {
    //.page {
        //float: inherit;
        //text-align: center;
    //}

    :global {
        .ant-table td {
            white-space: nowrap;
        }
    }
}

// 响应式表格，横向滚动
//noinspection Stylelint
.tableResponsive {
    table {
        th,
        td {
            white-space: nowrap;
        }
    }

    //noinspection Stylelint
    :global {
        .ant-table {
            width: 100%;
            overflow-x: scroll;
            overflow-y: hidden;
            -ms-overflow-style: -ms-autohiding-scrollbar;
            -webkit-overflow-scrolling: touch;
        }
    }
}

.actionWrap {
    //noinspection Stylelint
    :global(i.anticon) {
        margin-right: 5px;
    }
}

.moreAction {
    cursor: pointer;
    font-size: 14px;
}

.dropdownIcon {
    margin-left: 5px;
}

.horizontalAction {
    display: inline-block;
}

