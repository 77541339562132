.container {
    display: flex;
    flex: 1;
    overflow: hidden;
}

.sidePanel {
    width: 150px;
    padding-top: 10px;
}

