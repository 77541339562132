.container {
    display: flex;
}

.title {
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    color: #fff;
    font-weight: bold;
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
    width: 40px;
    line-height: 40px;
    min-height: 250px;
    padding: 36px 0;
    background-color: #438eee;
    background-image: linear-gradient(to bottom, #438eee 0%, #438eee 40%, #fff 80%);
    writing-mode: vertical-lr;
    letter-spacing: 8px;
    user-select: none;
}

.list {
    flex: auto;
    margin-left: 24px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: #f5f7f9;
    padding: 12px;
    border-radius: 10px;

    // 目的是使得最后一行左对齐
    &::after {
        content: '';
        flex: 1;
    }
}

