@import '~antd/es/style/themes/default';

.tree {
    min-height: 500px;
}

.setPrimary {
    display: none;
}

.item {
    &:hover {
        background-color: @background-color-light;

        .setPrimary {
            display: block;
        }
    }
}

