
.preview {
    display: flex;
    align-items: flex-end;
}

.previewBox {
    max-width: 270px;
}

