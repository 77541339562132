@import '~antd/es/style/themes/default';

.container {
    display: flex;
    align-items: center;
}

.button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 270px;
    height: 200px;

    span:last-child {
        font-size: 16px;
    }
}

.icon {
    font-size: 36px;
}

.description {
    margin-left: 24px;
}

