
.container {
    width: 400px;
    height: 560px;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    margin: auto;

    .content {
        background-color: #f5f7fa;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-content: space-around;

        .header {
            background-color: #4099ff;
            height: 40px;
        }

        .footer {
            flex: 1;
            display: flex;
            align-items: flex-end;

            .itemWrap {
                flex: 1;
                display: flex;
                flex-direction: column;

                .item {
                    line-height: 40px;
                    border: 1px solid #dcdde0;
                    text-align: center;
                    background-color: #e9eaeb;
                    font-size: 12px;
                }

                .subWrap {
                    border: 1px solid #cdd0d7;
                    margin: 5px;
                    background-color: #fff;

                    .subItem {
                        line-height: 40px;
                        text-align: center;
                        border-top: 1px solid #dcdde0;
                        background-color: #fafbfd;
                        font-size: 12px;
                        padding: 0 6px;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
    }
}

