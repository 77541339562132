@import '~antd/es/style/themes/default';

.container {
    display: flex;
    flex-wrap: wrap;
}

.card {
    margin-bottom: 24px;
    margin-right: 24px;
    width: 320px;
}

.cover {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.cardMeta {
    height: 90px;
}

.imgWrap {
    cursor: pointer;
}

.pagination {
    text-align: center;
    margin-bottom: 24px;
}

.newsTag {
    position: absolute;
    left: 5px;
    top: 5px;
}

.checkedIcon {
    font-size: 36px;
    color: #fff;
}

.checked {
    position: absolute;
    right: 0;
    top: 0;
    width: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 10px 0 10px 10px;

    &:hover {
        background-color: rgba(0, 0, 0, 0.8);

        .checkedIcon {
            color: @primary-color;
        }
    }
}

