.container {
    display: flex;
}

.thumbImg {
    width: 100px;
    height: 100px;
    object-fit: cover;
}

