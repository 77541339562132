.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.mobileHeader {
    padding: 0 16px;
}

.headerLogo {
    display: flex;
    color: white;
    font-size: 16px;
    align-items: center;
}

.backLink {
    color: white;
}

