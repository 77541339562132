@import '~antd/es/style/themes/default';

.item {
    padding: 12px 16px;
    cursor: pointer;

    &:hover {
        background-color: @menu-item-active-bg;
    }
}

.selItem {
    background-color: @menu-item-active-bg;
}

