@import '~antd/es/style/themes/default';

.overview {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    :global {
        video,
        audio {
            outline: none;
        }
    }
}

.close {
    position: fixed;
    top: 40px;
    right: 40px;
    width: 64px;
    height: 64px;
    color: #fff;
    font-size: 48px;
    cursor: pointer;

    &:hover {
        color: @normal-color;
    }
}

.title {
    font-size: 18px;
    margin-bottom: 24px;
    color: #fff;
    background-color: #000;
    padding: 8px 16px;
    border-radius: 100px;
}

.description {
    margin-top: 24px;
    color: #fafafa;
    background-color: #000;
    padding: 8px 16px;
    border-radius: 10px;
}

