@import '~antd/es/style/themes/default';

.previewActions {
    background-color: @primary-color;
    position: absolute;
    bottom: 0;
    width: 100%;
    display: none;
    color: #fff;
    justify-content: space-between;
    align-items: center;
}

.previewAction {
    padding: 4px 8px;
}

.orderActions {
    width: 48px;
    display: flex;
    justify-content: space-between;
}

.preview {
    border: 1px solid #d9d9d9;
    cursor: pointer;
    position: relative;

    &:hover {
        .previewActions {
            display: flex;
        }
    }
}

.selected {
    border: 1px solid @primary-color;
}

.previewCover {
    height: 116px;
    background-color: #ececec;
}

.detail {
    padding: 16px;
}

.oneLineDetail {
    display: flex;

    .title {
        flex: auto;
        line-height: 48px;
    }
}

.multiPreviews {
    .detail {
        .title {
            font-weight: normal;
        }
    }

    .previewCover {
        position: relative;

        .detail {
            display: flex;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba(0, 0, 0, 0.35);
            padding: 4px 16px;
            //opacity: 0.35;
            //background-image: linear-gradient(-180deg, rgba(0, 0, 0, 0) 0, #000 100%);

            .title {
                color: #fff;
                font-weight: bold;
            }
        }
    }

    .preview {
        &:not(.selected) {
            border-bottom: none;
        }

        &:not(.selected):last-child {
            border-bottom: 1px solid @border-color-split;
        }
    }
}

.thumbCover {
    background-color: #ececec;
    width: 48px;
    height: 48px;
    flex-shrink: 0;
}

.buttonWrap {
    text-align: right;
    margin-top: 24px;
}

.coverImg {
    width: 100%;
    height: 100%;
}

