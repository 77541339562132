.avatarWrap {
    text-align: center;
    margin-top: -140px;
    margin-bottom: 24px;
}

.avatar {
    box-shadow: 0 -5px 10px #000;
}

