@import '~antd/es/style/themes/default';

.closeBtn {
    visibility: hidden;
}

.closeIcon {
    color: #8c8c8c;
}

.item {
    &:hover {
        background-color: @background-color-light;

        .closeBtn {
            visibility: visible;
        }
    }
}

.itemInner {
    //padding-left: 16px;
}

