.container {
    display: flex;
    cursor: pointer;
}

.icon {
    width: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 8px;

    & > div {
        margin-bottom: 0;
    }
}

