.container {
    background-color: #f0f0f0;
    margin-top: 24px;
    padding: 8px;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
        background-color: #d9d9d9;
    }
}

