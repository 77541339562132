@import '~antd/es/style/themes/default';

.titleInput {
    color: @primary-color;
    border: none;
    border-left: 5px solid @primary-color;
    border-radius: 0;
    box-shadow: none !important;
    font-weight: 300;
    font-size: 24px;

    &::placeholder {
        color: @primary-color;
    }
}

