@app-header-color: #002c76;

.wrapper {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #eee;
    color: #333;
    overflow-y: auto;
    overflow-x: hidden;
}

.container {
    width: 100%;
    min-height: 100%;
    max-width: 960px;
    margin: 0 auto;
    background-color: #fff;
    padding-bottom: 100px;
}

@media (min-width: 768px) {
    .container {
        width: 90%;
    }
}

.headerTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    background-color: #eee;

    h1 {
        margin: 0 0 0 24px;
        color: @app-header-color;
        font-weight: 200;
        font-size: 16px;
        white-space: nowrap;
    }
}

.main {
    padding: 5% 5%;
    position: relative;
    font-size: 17px;
    font-weight: 400;
    //font: 300 1em/1.8 PingFang SC, Lantinghei SC, Microsoft Yahei, Hiragino Sans GB,
    //    Microsoft Sans Serif, WenQuanYi Micro Hei, sans-serif;
}

@media (min-width: 768px) {
    .main {
        padding: 5% 10%;
        border: 1px solid #ddd;
        border-top-width: 0;
        border-bottom-width: 0;
    }
}

