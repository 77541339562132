@import '~antd/es/style/themes/default';

.container {
    display: flex;
    flex: auto;
}

.localTree {
    flex: 1;
    margin-right: 12px;
}

.wecomTree {
    flex: 1;
    margin-left: 12px;
}

.description {
    margin-top: 24px;
    color: @text-color-secondary;
}

