.container {
    padding: 24px 24px 0;
    border-radius: 4px;
    background-color: #f5f5f5;
    border: 1px dashed #595959;
}

.item {
    margin-bottom: 12px;
    display: flex;
}

.title {
    width: 100px;
    flex-shrink: 0;
}

.tos {
    flex: auto;

    & > div {
        margin-bottom: 12px;
    }
}

