@import '~antd/es/style/themes/default';

.list {
    list-style-type: none;
    padding: 0;
}

.line {
    display: flex;
    padding: 12px 16px;

    &:hover {
        background-color: @menu-item-active-bg;
    }
}

.memo {
    font-weight: bold;
    flex: 1;
}

.actions {
    display: flex;
    width: 120px;
}

.action {
    flex: 1;
    text-align: right;
}

.read {
    color: #1da57a;
}

.write {
    color: #d9363e;
}

