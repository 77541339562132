@message-bg-color: #fff;

.container {
    padding: 24px;
    background-color: #f5f5f5;
    border-radius: 10px;
    //border: 1px solid;

    :global {
        .ant-tabs-card {
            & > .ant-tabs-nav {
                .ant-tabs-tab {
                    background: transparent;
                    border-color: transparent;
                }

                .ant-tabs-tab-active {
                    background-color: @message-bg-color;
                    border-color: @message-bg-color;
                }
            }

            .ant-tabs-nav::before {
                display: none;
            }

            .ant-tabs-content {
                margin-top: -16px;
            }

            .ant-tabs-content > .ant-tabs-tabpane {
                background-color: @message-bg-color;
                padding: 16px;
            }
        }
    }
}

