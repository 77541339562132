.container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    //pointer-events: none;
    background-repeat: repeat;
}

