.container {
    margin-bottom: 24px;
    display: flex;
    align-items: center;
}

.converPlaceholder {
    width: 123px;
    height: 52px;
    background-color: #f2f5f7;
    display: flex;
    align-items: center;
    justify-content: center;
}

.thumbImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
}

