@app-header-color: #002c76;

.container {
    display: flex;
    justify-content: space-between;
}

.titleWrap {
    display: flex;
}

.title {
    display: flex;
    align-items: center;
    height: 64px;

    h1 {
        margin: 0 0 0 24px;
        color: @app-header-color;
        font-weight: 200;
        white-space: nowrap;
    }
}

.subTitle {
    color: #595959;
    font-size: 1.2em;
    font-weight: 200;
    white-space: nowrap;
    margin: 0 0 0 48px;
}

.actions {
    display: flex;
    align-items: center;
}

.action {
    padding: 0 12px;
    cursor: pointer;
    display: block;
    border-bottom: 2px;
    height: 64px;

    a {
        display: block;
        color: @app-header-color;
    }

    &:hover {
        background-color: #fff;
        border-bottom: 2px solid @app-header-color;
    }
}

.divider {
    border-left-color: rgba(0, 0, 0, 0.2);
}

.menu {
    min-width: 120px;
}

