.description {
    background-color: #f5f5f5;
    padding: 24px 24px 12px;
    margin-top: 24px;
    border-radius: 4px;
    position: relative;
}

.descriptionLabel {
    position: absolute;
    top: -10px;
    left: 24px;
    background-color: #d9d9d9;
    color: #000;
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 4px;
}

.appName {
    color: #576b95;
    margin-left: 16px;
}

.attachs {
    margin-top: 24px;
    border-top: 3px double #eee;
    padding: 24px;

    li {
        padding-top: 10px;
    }
}

.attachCaption {
    font-weight: bold;
}

.attachSize {
    font-size: 13px;
    color: #8c8c8c;
}

.forbidCopy {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
}

