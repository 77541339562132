.upload {
    display: flex;
    align-items: center;
}

.description {
    margin-left: 24px;
}

.preview {
    display: flex;
    align-items: flex-end;
}

.previewBox {
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    padding: 8px;
    max-width: 300px;
    max-height: 300px;

    img {
        max-width: 100%;
        cursor: pointer;
    }
}

