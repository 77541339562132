.main {
    flex: auto;
    padding-right: 24px;
}

.preview {
    width: 270px;
    padding-top: 74px;
}

