@import '~antd/es/style/themes/default';

.tabPane {
    height: 500px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

// 单列内容
.tabContent1 {
    overflow-y: auto;
}

// 多列内容
.tabContent2 {
    display: flex;
    flex: auto;
    overflow: hidden;
}

.tabContentSider {
    width: 300px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.tabContentMain {
    flex: auto;
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    padding-left: 12px;
    border-left: 1px solid @divider-color;
    overflow: hidden;
}

.tabContentInner {
    overflow-y: auto;

}

