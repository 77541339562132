.header {
    display: flex;
    margin-bottom: 16px;
}

.main {
    flex: auto;
}

.item {
    margin-right: 8px;
}

