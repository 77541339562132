.container {
    display: flex;
    margin: 0 24px;
}

.msgType {
    flex-shrink: 0;
    width: 100px;
    padding-right: 12px;
    text-align: right;
    font-weight: bold;
}

.linkBtn {
    margin: 0 !important;
    padding: 0 !important;
    line-height: normal !important;
}

