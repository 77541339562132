.descriptions {

    // Descriptions 原本内嵌的 table 的 table-layout 会撑破布局
    table {
        table-layout: auto;
    }

    :global(.ant-descriptions-item-label) {
        width: 150px;
        color: #8c8c8c;
    }
}

.tags {
    display: flex;
    flex-wrap: wrap;
}

.tag {
    margin: 4px;
    cursor: pointer;
}

