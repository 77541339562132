.fixed {
    position: fixed;
    width: 100%;
    height: 100%;
}

.container {
    //height: 100vh;
    background: #f0f2f5 url('../../asset/login-bg.jpg') no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card {
    width: 900px;
    height: 500px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 0 100px 1px rgba(0, 0, 0, 0.5);
    display: flex;
    overflow: hidden;
}

.form {
    flex: 1;
    padding: 0 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .title {
        font-size: 16px;
        font-weight: lighter;
        color: #008cff;
    }

    h1 {
        font-weight: lighter;
        color: #008cff;
    }
}

.submitWrap {
    :global {
        // 去掉默认按钮背景色配置
        .ant-btn {
            background: none;
        }
    }

    .submit {
        width: 100%;
        transition: 0.5s;
        color: #fff;
        background-size: 200% auto;
        background-image: linear-gradient(to right, #a0caf6 0%, #438eee 51%, #a0caf6 100%);
        border: none;

        &:hover,
        &:focus {
            color: #fff;
            background-position: right center;
        }
    }
}


.forget {
    text-align: center;
}

