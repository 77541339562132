@import '~antd/es/style/themes/default';

.container {
    display: flex;
}

.mainPanel {
    flex: auto;
    //border: 1px solid red;
}

.selectedPanel {
    width: 300px;
    margin-left: 24px;
    padding-left: 24px;
    border-left: 1px solid @divider-color;
    height: 560px;
    overflow-y: auto;
}

